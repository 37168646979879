import { useEffect } from 'react';

const ApplyStyleToGifImages = () => {
  useEffect(() => {
    // 画面幅がタブレットサイズかどうかをチェックする関数
    const isTabletWidth = width => width <= 768;

    // すべてのimgタグを取得し、GIF画像に対してスタイルを適用する関数
    const applyStyleToGifImages = () => {
      const images = document.querySelectorAll('img');
      images.forEach(img => {
        if (img.src.endsWith('.gif')) {
          // スタイルをオブジェクトとして定義
          const style = isTabletWidth(window.innerWidth)
            ? {
                width: '100%',
              }
            : {
                width: 'auto',
                margin: '0 auto',
                display: 'block',
                maxWidth: '100%',
                maxHeight: '100%',
              };

          // オブジェクトのキーをループしてスタイルを適用
          Object.keys(style).forEach(key => {
            img.style[key] = style[key];
          });
        }
      });
    };

    // 初回実行と画面サイズ変更時にスタイルを適用
    applyStyleToGifImages();
    window.addEventListener('resize', applyStyleToGifImages);

    // コンポーネントがアンマウントされる時にイベントリスナーを削除
    return () => window.removeEventListener('resize', applyStyleToGifImages);
  }, []); // 空の依存配列を渡して、コンポーネントのマウント時にのみ実行されるようにする

  return null; // このコンポーネントはビジュアルを持たない
};

export default ApplyStyleToGifImages;
