import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Iframely = () => {
  useEffect(() => {
    // コンポーネント描画時に埋め込みコードを描画する
    if (window && window.iframely) window.iframely.load();
  }, []);

  return (
    <Helmet>
      <script
        type="text/javascript"
        src="//cdn.iframe.ly/embed.js"
      />
    </Helmet>
  );
};

export default Iframely;
